@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "./assets/scss2/custom.scss";

// //Import Light mode
@import './assets/scss2/style.scss';

//Import Dark RTL mode
// @import './assets/scss2/style-dark-rtl.scss';

//Import RTL mode
// @import './assets/scss2/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss2/style-dark.scss';


html,
body,
.flex-vertical {
  margin: 0;
}
.flex-vertical {
  display: flex;
  flex-flow: column;
}
.flags-table {
  display: flex;
  flex-flow: column;
  flex: 1;
}
.custom-modal-style-fullpage{
  max-width: 2000px;
  width: 80%;
}
.class-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
.main-container {
  display: flex;
}
.hideScrollbar::-webkit-scrollbar{
  display: none;
 }

 .table {
  display: flex;
  table-layout: auto;
  width: 100%;
  overflow-x: hidden;
  }

tbody {
  overflow-y: scroll;
  width: 100%;
  padding-left: 1px;
}
.red-box {
  margin: auto;
}
thead {
  display: flex;
  width: 100%;
  flex-shrink: 0;
}
tr {
  display: flex;
  flex-shrink: 0;
  width: 100%;
}
th,
td {
  display: table-column;
}
th ~th,
td ~td {
  flex: 1 1 22%;
  word-wrap: normal;
}
.report-cell-header{
  font-size: smaller;
}
.report-cell{
  font-size: smaller;
  padding-right: 5px;
}
h1,
footer {
}

.table-cell-list{
  list-style: none;
  padding: 0
}

#brandLogo {
  display:none;
}
@media only screen and (min-width: 768px) {
  #brandLogo {
    height: 50px;
    display:inline-block;;
  }
}

@media (max-width: 768px) {
  .custom-table-margin{
    margin-top: 50px
  }
}
